angular.module('app.controllers').controller('HomeCtrl', [
  '$scope','$rootScope', '$cookies', '$timeout', 'smoothScr', 'PaginatedDataTable', 'APIArticles', '$location', '$anchorScroll', 'APIData'
  (self,root, $cookies, $timeout, smoothScr, PaginatedDataTable, APIArticles, $location, $anchorScroll, APIData) ->
    self.filterParams =
      exhibitorId: ''
      category: ''

    self.categories = []

    if $cookies.getObject('homeFilterParams')
      self.filterParams = $cookies.getObject('homeFilterParams')

    self.articlesTable = new PaginatedDataTable({
      filterData: (data) ->
        data.filter((value) ->
          isValid = true
          if isValid && self.filterParams.exhibitorId
            isValid =  value.e_id == parseInt(self.filterParams.exhibitorId)

          if isValid && self.filterParams.category
            isValid = value.category.toLowerCase() == self.filterParams.category.toLowerCase()

          return isValid
        )
      params:
        page: 1
        limit: 12
      postPageChange: () ->
        $anchorScroll.yOffset = 350
        $anchorScroll('articlesList')

    })

    if (root.articles)
      self.articlesTable.setData(root.articles)
    else
      root.$on('articlesLoaded', (event, data) ->
        self.articlesTable.setData(data)
      )

    APIArticles.query({type: 'exhibitors', full: 'true'}, (data) ->
      self.exhibitors = data
    , (error) ->
      console.error(error)
    )

    APIData.query({slug: 'categories'}, (data) ->
      self.categories = data
    , (error) ->
      console.log(error)
    )

    self.applyFilter = () ->
      $cookies.putObject('homeFilterParams', self.filterParams)

      self.articlesTable.params.page = 1
      self.articlesTable.transformData()
      $timeout () -> smoothScr.scroll("#articlesList", 0)
])