angular.module('app.controllers').controller('HomeCtrl', [
  '$scope',
  '$rootScope',
  '$cookies',
  '$timeout',
  'smoothScr',
  'PaginatedDataTable',
  'APIArticles',
  '$location',
  '$anchorScroll',
  'APIData',
  function(self,
  root,
  $cookies,
  $timeout,
  smoothScr,
  PaginatedDataTable,
  APIArticles,
  $location,
  $anchorScroll,
  APIData) {
    self.filterParams = {
      exhibitorId: '',
      category: ''
    };
    self.categories = [];
    if ($cookies.getObject('homeFilterParams')) {
      self.filterParams = $cookies.getObject('homeFilterParams');
    }
    self.articlesTable = new PaginatedDataTable({
      filterData: function(data) {
        return data.filter(function(value) {
          var isValid;
          isValid = true;
          if (isValid && self.filterParams.exhibitorId) {
            isValid = value.e_id === parseInt(self.filterParams.exhibitorId);
          }
          if (isValid && self.filterParams.category) {
            isValid = value.category.toLowerCase() === self.filterParams.category.toLowerCase();
          }
          return isValid;
        });
      },
      params: {
        page: 1,
        limit: 12
      },
      postPageChange: function() {
        $anchorScroll.yOffset = 350;
        return $anchorScroll('articlesList');
      }
    });
    if (root.articles) {
      self.articlesTable.setData(root.articles);
    } else {
      root.$on('articlesLoaded',
  function(event,
  data) {
        return self.articlesTable.setData(data);
      });
    }
    APIArticles.query({
      type: 'exhibitors',
      full: 'true'
    },
  function(data) {
      return self.exhibitors = data;
    },
  function(error) {
      return console.error(error);
    });
    APIData.query({
      slug: 'categories'
    },
  function(data) {
      return self.categories = data;
    },
  function(error) {
      return console.log(error);
    });
    return self.applyFilter = function() {
      $cookies.putObject('homeFilterParams',
  self.filterParams);
      self.articlesTable.params.page = 1;
      self.articlesTable.transformData();
      return $timeout(function() {
        return smoothScr.scroll("#articlesList",
  0);
      });
    };
  }
]);
